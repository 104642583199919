/**
 * Fades in elements while scrolling
 * @constructor
 * @param {object} - Stores all the options for this instance
 */
class PmdrScrollFadeIn {

    constructor(args) {
        this.targets = document.querySelectorAll(args.targets);
        this.render();
    }

    render() {

        Lazy(this.targets).each((e, i) => {

            const top = e.getBoundingClientRect().top;

            // Doing nothing if the element is below the viewport
            if ( top < window.innerHeight ) {

                const threshold = window.innerHeight < 1000 ? window.innerHeight * 0.7 : 200;

                // If the element is below the threshold
                if ( top > threshold ) {
                    
                    const thresholdHeight = window.innerHeight - threshold;
                    const elemPosition = window.innerHeight - top;
                    const opacity = elemPosition / thresholdHeight;
                    
                    e.style.opacity = opacity + 0.2; // because the starting/minimum opacity is 0.2

                } else {

                    e.style.opacity = 1;

                }

            } else {

                e.style.opacity = 0.2;

            }

        });

        requestAnimationFrame(this.render.bind(this));

    }

}
