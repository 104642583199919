function checkMicrosoft() {
    var isIE = /*@cc_on!@*/false || !!document.documentMode, // Internet Explorer 6-11
    isEdge = !isIE && !!window.StyleMedia; // Edge 20+
 
    return isIE || isEdge        
}

/**
 * Class that manages parallax items
 * @param {boolean} [running=false] - If the instance has to run automatically when it's initialized
 */
class PmdrParallax {
    
    /**
     * @private
     */
    constructor() {
        this.running     = false; 
        this.items       = null;
        this.runOnMobile = false;
    }
    
    /**
     * Runs the instance
     */
    init() {
        var query = '.parallax-item'
        if (checkMicrosoft()) {
            query = '.parallax-item:not(.parallax-item--hidden)'
            var toHide = document.querySelectorAll('.parallax-item--hidden')
            for (var i = 0; i < toHide.length; i++) {
                toHide[i].classList.add('d-none')
            }
        }

        this.items = document.querySelectorAll(query);
        this.running = true;
        this.render();
    }
    
    /**
     * Stops the instance
     */
    stop() {
        this.running = false;
    }

    /**
     * @private
     */
    getBodyHeight() {

        const body = document.body;
        const html = document.documentElement;

        return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

    }

    /**
     * @private
     */
    getWindowScroll() {
        return window.scrollY || window.pageYOffset;
    }

    /**
     * @private
     */
    getWindowScrolledPerc() {
        return ( this.getWindowScroll() / this.getBodyHeight() ) * 100;
    }
    
    /**
     * @private
     */
    render() {

        const _self = this;

        for( let i = 0, len = this.items.length; i < len; i++ ) {

            const item           = this.items[i], 
                  runOnMobile    = item.dataset.parallaxOnMobile === 'true' || false, 
                  limit          = parseInt(item.dataset.parallaxLimit) || false, 
                  parallaxFactor = Math.abs( item.dataset.parallaxFactor ) || 1, 
                  parallaxOn     = item.dataset.parallaxOn || 'elem-in-view', 
                  direction      = item.dataset.parallaxDirection || 'down', 
                  dir            = direction === 'up' ? -1 : 1;

            let scrolled, scrolledPerc;
            
            
            if ( Foundation.MediaQuery.atLeast('large') || runOnMobile ) {

                // Calculating parallax values
                if ( parallaxOn === 'page-scroll') { // Parallax effect applied on page scroll

                    scrolledPerc = this.getWindowScrolledPerc();

                } else if ( parallaxOn === 'elem-in-view' ) { // Parallax applies only when the element reaches the viewport

                    scrolled     = item.getBoundingClientRect().top;
                    scrolledPerc = 100 - (scrolled / window.innerHeight) * 100;

                }

                // If a limit has been set
                if ( limit && scrolledPerc > limit ) scrolledPerc = limit;
                
                
                // Setting parallax values
                if ( parallaxOn === 'page-scroll' || scrolled < this.getBodyHeight() ) {

                    const transform = (parallaxFactor * scrolledPerc * dir).toFixed(2);

                    if ( Modernizr.csstransforms3d ) {
                        item.style.transform       = `translate3d(0, ${transform}%, 0)`;
                        item.style.webkitTransform = `translate3d(0, ${transform}%, 0)`;
                    } else {
                        item.style.transform       = `translate(0, ${transform}%)`;
                        item.style.webkitTransform = `translate(0, ${transform}%)`;
                    }
                }

            } else {

                // Resetting item's position (e.g. the window has been narrowed)
                item.removeAttribute('style');

            }
            
        }

        if ( this.running ) requestAnimationFrame(_self.render.bind(this));

    }
    
};