/**
 * Initializes a new jQuery Waypoint instance
 * @constructor
 * @param {object} targets - jQuery object of the target(s) element(s)
 * @param {string} [offset='90%'] - ::: Description coming soon :::
 * @param {boolean} [autoplay=false] - Whether or not Waypoint should start as soon as the class is instantiated.
 */
class PmdrWaypoint {

    constructor({targets, offset = '90%', autoplay = false}) {
        this.targets = targets;
        this.offset = offset;
        if (autoplay) this.init();
    }

    /**
     * Runs jQuery Waypoint with the current settings.
     * Triggered automatically if autoplay is set to TRUE.
     */
    init() {
        
        $(this.targets).waypoint({
            handler: function() {
                classie.add(this.element, 'waypoint-active');
            }, 
            offset: this.offset
        });

    }

}
