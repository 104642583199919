/**
 * Fades out elements while scrolling
 * @constructor
 * @param {object} - Stores all the options for this instance
 */
class PmdrScrollFadeOut {

    constructor(args) {
        this.targets = document.querySelectorAll(args.targets);
        this.render();
    }

    render() {

        Lazy(this.targets).each((e, i) => {

            const bottom    = e.getBoundingClientRect().bottom;
            const threshold = window.innerHeight < 1000 ? window.innerHeight * 0.45 : 250;

            if (bottom < threshold) {

                /**
                 * If opacity is lower than 0.2 we set it to 0.2
                 */
                const opacity = bottom / threshold > 0.2 ? bottom / threshold : 0.2;
                e.style.opacity = opacity;

            } else {

                e.style.opacity = 1;
                
            }

        });

        requestAnimationFrame(this.render.bind(this));

    }

}
