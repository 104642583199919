(function($) {

    var $body = $('body'), 
        scrollDown, 
        inView, 
        introDone = false, 
        menuOpen = false, 
        backgroundCheckElems, 
        backgroundCheckActive = false, 
        landingMobileReel;

    $(document).foundation();

    SmoothScroll({
        stepSize: 70, 
        animationTime: 800, 
        accelerationDelta: 100, 
        accelerationMax: 7
    });

     
    /**
     * Stuff
     */
    var pageCurrTransformOriginY, 
        container;

    var isWindows = function() {

        return navigator.platform.indexOf('Win') > -1;

    }

    var isSafari = function() {

        /*
         * REF: https://stackoverflow.com/questions/7944460/detect-safari-browser
         */
        var ua = navigator.userAgent.toLowerCase(); 

        if (ua.indexOf('safari') != -1) { 
            if (ua.indexOf('chrome') > -1) {
                return false;
            } else {
                // alert("2") // Safari
                return true;
            }
        }

        return false;

    }


    if ( !isWindows() ) {
        classie.addClass(document.body, 'no-windows');
    } else {
        classie.addClass(document.body, 'is-windows');
    }

    if ( !isSafari() ) {
        classie.addClass(document.body, 'no-safari');
    } else {
        classie.addClass(document.body, 'is-safari');
    }


    function getScrollY() {
        return window.scrollY || window.pageYOffset;
    }

    function hideBars() {

        $topBar.animate({
            yPercent: 0, 
            opacity: 0, 
            duration: 800, 
            ease: Power1.easeOut
        });

    }

    function showBars() {

        $topBar.animate({
            yPercent: 100, 
            opacity: 1, 
            duration: 800, 
            ease: Power1.easeOut
        });

    }

    function setBarbaContainer() {
        container = document.querySelector('.barba-container');
    }

    function setTransformOrigin() {
        pageCurrTransformOriginY = (window.innerHeight / 2) + getScrollY();
        container.style.transformOrigin = '50% ' + pageCurrTransformOriginY + 'px 0';
    }

    function setupInView() {
        inView = document.querySelectorAll('.in-view-check');
    }

    function animateInView() {

        Lazy(inView).each(function(i) {

            var bounds    = i.getBoundingClientRect(), 
                inViewMax = parseInt(i.getAttribute('data-in-view-max'));

            if ( bounds.top < window.innerHeight && bounds.top > 0 ) {

                var visiblePerc = ((window.innerHeight - bounds.top) / bounds.height) * 100;
                if (visiblePerc > inViewMax) visiblePerc = inViewMax;
                i.setAttribute('data-revealed', visiblePerc + '%');

                var transformAttribute = i.getAttribute('data-in-view-transform');

                if ( transformAttribute === 'padding' ) {

                    var value = 10 - (10 / 100) * visiblePerc;
                    i.style.padding = value + '%';

                } else if ( transformAttribute === 'scaleX' ) {

                    i.style.webkitTransform = 'scaleX(' + (1 - visiblePerc / 100) + ')';
                    i.style.transform = 'scaleX(' + (1 - visiblePerc / 100) + ')';

                }

            }

        });

    }

    function initHomeParallax() {

        new Parallax(document.querySelector('.parallax-background'), {
            invertX: false, 
            invertY: false, 
            scalarY: 0, 
            scalarX: 3, 
            limitX: 35, 
            limitY: 35
        });

        new Parallax(document.querySelector('.home-top__inner > div'), {
            invertX: false, 
            invertY: false, 
            scalarY: 0, 
            scalarX: 3, 
            limitX: 35, 
            limitY: 35
        });

    }

    function initPageParallax() {

        new Parallax(document.querySelector('.page-hero'), {
            invertX: false, 
            invertY: false, 
            scalarY: 0, 
            scalarX: 3, 
            limitX: 35, 
            limitY: 35
        });

    }


    function initBackgroundInvert() {

        backgroundCheckActive = true;
        backgroundCheckElems = document.querySelectorAll('.background-invert-target');

        function render() {

            for ( var i = 0, len = backgroundCheckElems.length; i < len; i++ ) {

                // If the element is not visible we do nothing, preventing weird behavior
                if ( backgroundCheckElems[i].offsetParent === null ) continue;

                var bounds = backgroundCheckElems[i].getBoundingClientRect(), 
                    threshold = backgroundCheckElems[i].dataset.bgInvertThreshold ? parseFloat(backgroundCheckElems[i].dataset.bgInvertThreshold) : 0.45;

                if ( bounds.top < window.innerHeight * threshold ) {

                    if ( Barba.HistoryManager.currentStatus().namespace === 'landing' ) {
                        if ( classie.has(document.body, 'inverted') ) classie.remove(document.body, 'inverted');
                    } else {
                        if ( !classie.has(document.body, 'inverted') ) classie.add(document.body, 'inverted');
                    }

                } else {

                    if ( Barba.HistoryManager.currentStatus().namespace === 'landing' ) {
                        if ( !classie.has(document.body, 'inverted') ) classie.add(document.body, 'inverted');
                    } else {
                        if ( classie.has(document.body, 'inverted') ) classie.remove(document.body, 'inverted');
                    }

                }

            }

            if ( backgroundCheckActive ) requestAnimationFrame(render);

        }

        if (backgroundCheckElems) render();

    }

    var twitterMeta     = document.querySelectorAll('[name^="twitter:"]'), 
        ogMeta          = document.querySelectorAll('[property^="og:"]'), 
        descriptionMeta = document.querySelector('[name="description"]');

    function updateMetatag(newPageRawHTML) {
        
        var parser             = new DOMParser(), 
            newDoc             = parser.parseFromString(newPageRawHTML, 'text/html'), 
            newTwitterMeta     = newDoc.querySelectorAll('[name^="twitter:"]'), 
            newOgMeta          = newDoc.querySelectorAll('[property^="og:"]'), 
            newDescriptionMeta = newDoc.querySelector('[name="description"]');
        
        // Updating Twitter Card metatags
        Lazy(twitterMeta).each(function(e, index) {
            var content = newTwitterMeta[index].getAttribute('content');
            e.setAttribute('content', content);
        });

        // Updating UpenGraph metatag
        Lazy(ogMeta).each(function(e, index) {
            var content = newOgMeta[index].getAttribute('content');
            e.setAttribute('content', content);
        });

        // Updating description metatag
        descriptionMeta.setAttribute('content', newDescriptionMeta.getAttribute('content'));

    }

    function setScrollDownOpacity() {
        
        if ( !scrollDown ) return;

        var opacity = 1 - ((getScrollY() / window.innerHeight) * 0.9);
        if ( opacity < 0 ) opacity = 0;
        scrollDown.style.opacity = opacity;

    }

    function fixFooterToBottom() {

        // Need this only on contacts page
        if ( Barba.HistoryManager.currentStatus().namespace !== 'contacts-page' ) return;

        var winH = window.innerHeight, 
            footerH = document.querySelector('.site-footer').clientHeight, 
            content = document.querySelector('.site-content');

        if ( winH > 1200 ) {

            // Window is too high. Attaching the footer at the bottom
            classie.addClass(document.body, 'footer-fixed-to-bottom');
            content.style.height = winH - footerH + 'px';

        } else {
            classie.removeClass(document.body, 'footer-fixed-to-bottom');
            content.removeAttribute('style');
        }

    }

    function initTwoStepForm() {

        new PmdrForm('.two-step-form__form');
        
        jQuery('.two-step-form__form #submit').on('click', function(e) {
            e.preventDefault();
            jQuery('.two-step-form__form').submit();
        });

        jQuery('.show-form-btn').on('click', function(e) {
            e.preventDefault();
            showTwoStepForm();
        });

    }

    function showTwoStepForm() {

        var tl = new TimelineMax({
            onComplete: function() {

                // This caused issues when run on landing pages, so this condition is a must ☯﹏☯
                if (Barba.HistoryManager.currentStatus().namespace !== 'landing')
                    TweenMax.set('.two-step-form', {clearProps: 'all'});

            }
        });

        tl
            .add('fadeout intro')
            .staggerTo('.theres-more, .wait, .show-form-btn', 0.4, {
                opacity: 0, 
                y: -30,
            }, 0.1)

            .add('set new height')
            .to('.two-step-form', 0.6, {
                height: function() {
                    return document.querySelector('.two-step-form__form').clientHeight;
                }
            })

            .add(function() {
                const wrap = document.querySelector('.two-step-form-wrapper')
                const form = document.querySelector('.two-step-form')
                const headerOffset = 120
                const elementPosition = wrap.getBoundingClientRect().top
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop
                const offsetPosition = (elementPosition + scrollTop) - headerOffset

                if (window.matchMedia("(min-width: 641px)").matches) {
                    form.scrollIntoView({ behavior: 'smooth', block: 'center' })   
                } else {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    })
                }
            })

            .add('hide intro')
            .set('.two-step-form__pre-form', { display: 'none' })
            .set('.form-title span, .form-subtitle, .two-step-form__col input, .two-step-form__row textarea, .form-privacy-text, .two-step-form__form__input .button', {
                opacity: 0,
                y: 30,
            })

            .add('form container appears')
            .set('.two-step-form__form-container', { position: 'static'})
            .set('.two-step-form__form-container', { opacity: 1, left: 0, zIndex: 0 })

            .add('form appears')
            .staggerTo('.form-title span, .form-subtitle, .two-step-form__col input, .two-step-form__row textarea, .form-privacy-text, .two-step-form__form__input .button', 0.5, {
                opacity: 1,
                y: 0,
            }, 0.1);

    }
    /**
     * End stuff
     */


    /**
     * Navigation
     */
    var openCloseNavEnabled = true;
    function showNav() {

        menuOpen = true;

        TweenMax
            .to( document.querySelector('.navigation'), 0.6, {
            zIndex: 20, 
            opacity: 1, 
            onComplete: function() {

                var targets = document.querySelectorAll('.site-navigation li, .navigation__subtitle, .works-navigation li');

                TweenMax.staggerFromTo(
                    targets, 
                    0.5, 
                    {
                        opacity: 0, 
                        x: -25
                    }, 
                    {
                        opacity: 1, 
                        x: 0, 
                        ease: Circ.easeOut
                    }, 
                    0.1
                );

            }
        });

        document.querySelector('.hamburger-icon__line.line-top').setAttribute('transform', 'translate(0 6) rotate(45 25.5 19)');
        document.querySelector('.hamburger-icon__line.line-bottom').setAttribute('transform', 'translate(0 -6) rotate(-45 25.5 31)');
        document.querySelector('.hamburger-icon__line.line-bottom').setAttribute('width', '16');

    }

    function hideNav(restoreBars) {

        var restoreBars = restoreBars || false;

        menuOpen = false;

        TweenMax.to( document.querySelector('.navigation'), 0.6, {
            opacity: 0, 
            onComplete: function() {

                if ( restoreBars ) showBars();

                var targets = document.querySelectorAll('.site-navigation li, .navigation__subtitle, .works-navigation li');
                
                classie.remove(document.body, 'menu-open');
                TweenMax.set(document.querySelector('.navigation'), {clearProps: "all"});
                TweenMax.set(targets, {clearProps: "all"});

            }
        });

        document.querySelector('.hamburger-icon__line.line-top').removeAttribute('transform');
        document.querySelector('.hamburger-icon__line.line-bottom').removeAttribute('transform');
        document.querySelector('.hamburger-icon__line.line-bottom').setAttribute('width', '8');

    }

    $body.on('click', '.open-menu', function() {

        if ( !openCloseNavEnabled ) return;
        openCloseNavEnabled = false;

        TweenMax.to(document.querySelector('.hamburger-icon__circle'), 0.6, {
            ease: Power1.easeOut, 
            strokeDashoffset: 320, 
            onComplete: function() {

                TweenMax.to(document.querySelector('.hamburger-icon__circle'), 0.3, {
                    stroke: 'white', 
                    strokeOpacity: 0
                });

                openCloseNavEnabled = true;

            }
        });
        
        $(this)
            .removeClass('open-menu')
            .addClass('close-menu');
        
        $('#hamburger-icon').addClass('animated');
        $('body').addClass('menu-open');

        showNav();

    });

    $body.on('click', '.close-menu', function() {

        if ( !openCloseNavEnabled ) return;
        openCloseNavEnabled = false;

        TweenMax.to(document.querySelector('.hamburger-icon__circle'), 0.3, {
            stroke: 'white', 
            strokeOpacity: 1, 
            onComplete: function() {

                TweenMax.to(document.querySelector('.hamburger-icon__circle'), 0.6, {
                    ease: Power1.easeOut, 
                    strokeDashoffset: 160
                });

                openCloseNavEnabled = true;

            }
        });

        $(this)
            .addClass('open-menu')
            .removeClass('close-menu');
        
        $('#hamburger-icon').removeClass('animated');

        hideNav(true);

    });

    var $topBar = $('.top-bar');
    /**
     * End Navigation
     */

    
    /*
     * Sticky sections
     */

    function initStickySections() {

        var sidebar = document.querySelector('.sidebar');

        if (!sidebar) return;
        
        new StickySidebar(sidebar, {
            containerSelector: '.sticky-sections',
            innerWrapperSelector: '.sidebar__inner',
            topSpacing: 150
        });

        var sections = document.querySelectorAll('.sticky-section');

        Lazy( sections ).each(function(section, index) {
    
            new Waypoint({
                element: section, 
                offset: 150, 
                handler: function(direction) {

                    // When changing page, the last element was marked as active instead of the first one, 
                    // this way we can prevent that behavior
                    if ( classie.hasClass(document.body, 'page-transition') ) return;
                    
                    var currentSection = (direction === 'down') ? parseInt(this.element.dataset.section) : parseInt(this.element.dataset.section) - 1;
                    if ( currentSection >= 0 ) toggleActiveStickySection(currentSection);
    
                }
            });
    
        });

    }

    var toggleActiveStickySection = function(index) {

        const oldSection = document.querySelector('.sidebar__inner li.active');

        if ( !oldSection ) return;

        const newSection = document.querySelector(`.sidebar__inner li:nth-child(${index + 1})`);

        if ( oldSection === newSection ) return;

        oldSection.classList.remove('active');
        newSection.classList.add('active');
        
        // stickySectionsAnimate(oldSection, 'close', () => stickySectionsAnimate(newSection, 'open'));
        // stickySectionsAnimate(oldSection, 'close');

    }


    /**
     * @param {domElement} target - The element to animate
     * @param {(open|close)} [action="open"] - If the target element has to be closed or opened
     * @param {function} callback - Action to trigger once the animation is completed
     */
    function stickySectionsAnimate(target, action, callback) {

        CustomEase.create('pmdrEaseIn', '.8, 0, 1, 1');
        
        const primaryColor = '#0030FF';
        
        const sectionLine      = target.querySelector('.insight__index__line');
        const sectionIndex     = target.querySelector('.insight__index');
        const sectionTitleIdle = target.querySelector('.insight__title--idle');
        const sectionMain      = target.querySelector('.insight__main');

        const timelineParameters = {
            paused: true, 
            onComplete: () => {

                if ( action === 'open' ) {
                    target.classList.add('active');
                } else if ( action === 'close' ) {
                    target.classList.remove('active');
                }

                if (callback) callback();

            }
        }

        const closeTimeline = new TimelineMax({
            ...timelineParameters
        });

        closeTimeline
            .add('start')
            .set(sectionTitleIdle, {color: primaryColor})
            .to(sectionLine, 0.3, {scaleX: 0, ease: 'pmdrEaseIn'}, 'start')
            .to(sectionIndex, 0.3, {color: primaryColor}, 'start+=0.05')
            .to(sectionTitleIdle, 0.4, {marginLeft: 10, opacity: 1}, 'start+=0.05')

            .add('animateContent')
            .set(sectionMain, {overflow: 'hidden'})
            .to(sectionMain, 0.3, {opacity: 0}, 'animateContent')
            .fromTo(sectionMain, 0.3, {height: 250}, {height: 0})


        const openTimeline = new TimelineMax({
            ...timelineParameters
        });

        openTimeline
            .add('start')
            .to(sectionLine, 0.3, {scaleX: 1}, 'start')
            .to(sectionIndex, 0.3, {color: '#fff'}, 'start')
            .to(sectionTitleIdle, 0.4, {opacity: 0, marginLeft: 0}, 'start+=0.1')

            .add('animateContent')
            .set(sectionTitleIdle, {color: '#fff'})
            .fromTo(sectionMain, 0.3, {height: 0}, {height: 250}, 'animateContent')
            .fromTo(sectionMain, 0.3, {opacity: 0, y: -10}, {opacity: 1, y: 0}, 'animateContent+=0.3')
            .set(sectionMain, {overflow: 'auto'});


        // Triggering immediately the animations
        if ( action === 'open' ) {
            openTimeline.play();
        } else if ( action === 'close' ) {
            closeTimeline.play();
        }

    }

    
    /**
     * Modals
     * @param {string} modalId - [Required] The ID of the target modal
     * @param {boolean} [isAjax] - Whether the content of the modal is loaded using AJAX
     * @param {string} [modalUrl] - The URL of the content to load into the modal box
     */
    function openModal(modalId, isAjax, modalUrl) {

        hideBars();
        classie.addClass(document.body, 'modal-open');

        if ( isAjax ) {

            var $modal = $('#ajax-modal'), 
                $modalInner = $modal.children('.modal__inner');

            $modal.addClass('open');

            var tl = new TimelineMax();

            tl
                // Modal partially appears
                .set($modal, {zIndex: 999})
                .to($modal, 0.5, {opacity: 0.5, onComplete: function() {

                    // Loading content
                    $modalInner.load(modalUrl, function() {

                        // After content is loaded the modal fully appears
                        TweenMax.to($modal, 0.5, {delay: 0.5, opacity: 1, onComplete: function() {

                            // Staggering elements
                            var targets = $modal.find('.modal__header, .modal__details, .modal__gallery figure');
                            TweenMax.staggerTo(targets, 0.5, {opacity: 1, y: 0}, 0.2);

                        }});
                    });

                }});

        } else {

            var target = document.getElementById(modalId);
            
            classie.addClass(target, 'open');
    
            TweenMax.fromTo(target, 0.3, {
                yPercent: 0
            }, {
                zIndex: 999, 
                opacity: 1, 
                yPercent: 0
            });
    
            if ( landingMobileReel && modalId === 'modal-reel' && landingMobileReel[0].isPaused() ) {

                setTimeout(function() {
                    landingMobileReel[0].play();
                }, 500);
    
            }

        }
        
    }

    /**
     * @param {modalId} - The ID attribute of the target modal box
     */
    function closeModal(modalId) {

        showBars();
        classie.removeClass(document.body, 'modal-open');

        var target = document.getElementById(modalId);

        classie.removeClass(target, 'open');

        TweenMax.to(target, 0.3, {
            opacity: 0,  
            onComplete: function() {
                target.style.zIndex = -10;
            }
        });

        // Pausing the reeel video
        if ( landingMobileReel ) landingMobileReel[0].pause();

        // Emptying the ajax modal
        if ( modalId === 'ajax-modal' ) $('#ajax-modal .modal__inner').empty();

    }


    /*
     * Su iOS a volte l'evento click non parte, in questo caso devo aprire le lightbox giostrandomela con
     * gli eventi touchstart e touchend
     */
    var touchInitialY = 0, 
        touchFinalY = 0;

    function pmdrStartTouch() {
        return function(e) {
            touchInitialY = e.changedTouches[0].clientY;
        };
    }

    $body.on('touchstart', '.open-modal', pmdrStartTouch());

    function pmdrEndTouch() {
        return function(e) {

            touchFinalY = e.changedTouches[0].clientY;
            var touchDistance = Math.abs(touchInitialY - touchFinalY);

            if ( touchDistance < 50 ) {

                e.preventDefault();
                var isAjax = $(this)[0].hasAttribute('data-modal-url');
        
                if ( isAjax ) {
                    openModal( null, true, $(this).attr('data-modal-url') );
                } else {
                    openModal( $(this).attr('data-modal-id') );
                }

            }

        };
    }

    $body.on('touchend', '.open-modal', pmdrEndTouch());
    /*
     * Fine eventi touch iOS
     */


    $body.on('click', '.open-modal', function(e) {

        e.preventDefault();
        var isAjax = $(this)[0].hasAttribute('data-modal-url');

        if ( isAjax ) {
            openModal( null, true, $(this).attr('data-modal-url') );
        } else {
            openModal( $(this).attr('data-modal-id') );
        }

    });

    $body.on('click', '.close-modal', function() {
        closeModal( $(this).attr('data-modal-id') );
    });

    $body.on('click', '.modal__inner', function(e) {

        // Preventing modal closing when clicking on children
        // REF: https://stackoverflow.com/questions/9183381/how-to-have-click-event-only-fire-on-parent-div-not-children
        if ( e.target !== this ) return;

        closeModal( $('.modal.open')[0].id );
        
    });


    $body.on('keyup', '.floating-label input, .floating-label textarea', function() {
        
        var valueLength = $(this).val().length;
        
        if ( valueLength ) {
            $(this)
                .removeClass('empty')
                .addClass('not-empty');
        } else {
            $(this)
                .removeClass('not-empty')
                .addClass('empty');
        }

    });


    function initScrollDownLoop() {

        /*
         * Loop animation of the scroll element
         */
        const scrollDownTl = new TimelineMax({
            delay: 3, 
            repeat: -1, 
            repeatDelay: 3
        });

        const scrollDownBefore = document.querySelector('.scroll-down__before');
        const scrollDownAfter = document.querySelector('.scroll-down__after');

        scrollDownTl

            .add('disappear')
            .to(scrollDownBefore, 0.4, {ease: Power1.easeIn, width: '25%', left: '100%'})
            .to(scrollDownAfter, 0.4, {ease: Power1.easeIn, width: '70%', left: '100%', delay: -0.38})

            .add('back')
            .set(scrollDownBefore, {width: '25%', left: '-100%'})
            .set(scrollDownAfter, {width: '70%', left: '-100%'})

            .add('reappear')
            .to(scrollDownBefore, 1, {left: 0, width: '45%', ease: Power1.easeOut})
            .to(scrollDownAfter, 1, {left: 0, width: '5%', ease: Power1.easeOut});

    }


    Parsley.addMessages('it', {
        defaultMessage: "Questo campo sembra essere non valido.",
        type: {
            email:        "Questo campo deve essere un indirizzo email valido.",
            url:          "Questo campo deve essere un URL valido.",
            number:       "Questo campo deve essere un numero valido.",
            integer:      "Questo campo deve essere un numero valido.",
            digits:       "Questo campo deve essere di tipo numerico.",
            alphanum:     "Questo campo deve essere di tipo alfanumerico."
        },
        notblank:       "Questo campo non deve essere vuoto.",
        required:       "Questo campo &egrave; richiesto.",
        pattern:        "Questo campo non &egrave; corretto.",
        min:            "Questo campo deve essere maggiore di %s.",
        max:            "Questo campo deve essere minore di %s.",
        range:          "Questo campo deve essere compreso tra %s e %s.",
        minlength:      "Questo campo &egrave; troppo corto. La lunghezza minima &egrave; di %s caratteri.",
        maxlength:      "Questo campo &egrave; troppo lungo. La lunghezza massima &egrave; di %s caratteri.",
        length:         "La lunghezza di questo campo deve essere compresa fra %s e %s caratteri.",
        mincheck:       "Devi scegliere almeno %s opzioni.",
        maxcheck:       "Devi scegliere al pi&ugrave; %s opzioni.",
        check:          "Devi scegliere tra %s e %s opzioni.",
        equalto:        "Questo campo deve essere identico."
    });

    Parsley.setLocale('it');

    function initLandingForm() {

        var $landingFormAppears = $('.landing-form__appears');
        
        $('[name^="interested_in"]').on('change', function() {
            $landingFormAppears.addClass('open').slideDown();
        });

    }

    /**
     * @param {string} html - [Required] String containing the HTML of the whole page
     * @return {DOM} - Document Object Model of the submitted page
     */
    function parseHTML(html) {
        var parser = new DOMParser();
        return parser.parseFromString(html, 'text/html');
    }

    /**
     * Updates all the elements external to the BarbaJS container
     * @param {string} - [Required] - String containing the HTML of the whole page
     */
    function updateExternalHTML(newPageRawHTML) {

        var newDoc = parseHTML(newPageRawHTML);

        // Updating top bar
        var nav = newDoc.querySelector('.top-bar');    
        document.querySelector('.top-bar').innerHTML = nav.innerHTML;

        // Updating contacts overlay info
        var contactsInfo = document.querySelector('.contacts__info');
        var newContactsInfo = newDoc.querySelector('.contacts__info');
        if (contactsInfo) contactsInfo.innerHTML = newContactsInfo.innerHTML;

    }

    /**
     * BarbaJS configuration
     */
    var MovePage = Barba.BaseTransition.extend({

        start: function() {

            backgroundCheckActive = false;

            Promise
                .all([this.newContainerLoading])
                .then(this.movePages.bind(this));
        }, 
        
        hideNav: function() {
            hideBars();
            return $topBar.promise();
        }, 

        movePages: function() {

            var _this = this;

            this.newContainer.style.visibility = 'hidden';
            this.newContainer.style.position   = 'fixed';
            this.newContainer.style.transform  = 'translateX(100%)';
            this.newContainer.style.left       = 0;
            this.newContainer.style.top        = 0;
            this.newContainer.style.right      = 0;

            classie.remove(document.body, 'inverted');

            var pageTimeline = new TimelineMax({

                onStart: function() {
                    classie.addClass(document.body, 'page-transition');
                }, 

                onComplete: function() {
                    TweenMax.set(document.getElementById('barba-wrapper'), {clearProps: 'overflow'});
                    TweenMax.set(document.querySelector('.page-transition-overlay'), {clearProps: 'all'});
                    classie.removeClass(document.body, 'page-transition');
                }

            });

            pageTimeline.set(document.getElementById('barba-wrapper'), { overflow: 'hidden' });

            pageTimeline
                .fromTo(document.querySelector('.page-transition-overlay'), 0.8, {
                    xPercent: 0, 
                }, {
                    xPercent: 100, 
                    ease: Quart.easeOut, 
                    onComplete: function() {

                        _this.done();

                        // When coming from the contacts page, on high screens, the footer keeps living
                        // at the bottom of the page, this fixes the universe's balance
                        classie.removeClass(document.body, 'footer-fixed-to-bottom');

                    }
                })
                .set(this.newContainer, { clearProps: 'all' } )
                .to(document.querySelector('.page-transition-overlay'), 0.8, {
                    delay: 0.1, 
                    xPercent: 200, 
                    ease: Quart.easeOut
                });

        }

    });

    Barba.Pjax.getTransition = function() {
        return MovePage;
    };

    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {

        // This prevents the new container to inherit the transformOrigin property from its predecessor
        container.style.transformOrigin = '0 ' + window.innerHeight / 2 + 'px 0';

        updateExternalHTML(newPageRawHTML);

        updateMetatag(newPageRawHTML);
        objectFitImages('.single-work-hero__image');

    });

    Barba.Dispatcher.on('linkClicked', function(HTMLElement) {

        // hideNav(false);
        setTransformOrigin();

    });

    Barba.Dispatcher.on('initStateChange', function() {
        if (typeof ga === 'function') {
            ga('send', 'pageview', location.pathname);
        }
    });

    Barba.Dispatcher.on('transitionCompleted', function(currentStatus, prevStatus) {

        // Ref: https://docs.hotjar.com/v1.0/docs/hotjar-on-single-page-apps
        if ( typeof hj === 'function' ) hj('stateChange', location.pathname);

        window.scrollTo(0, 0);

        setTimeout(function() {
            showBars();
        }, 500);

        setTimeout(function() {
            new PmdrWaypoint({targets: '.waypoint-target', autoplay: true});
        }, 1000);

        var pmdrParallax = new PmdrParallax();
        pmdrParallax.init();

        new PmdrScrollFadeOut({targets: '.scroll-fade-target'});

        new PmdrScrollFadeIn({targets: '.scroll-fadein-target'});
        
        setBarbaContainer();
        initBackgroundInvert();
        setupInView();
        render();

        scrollDown = document.querySelector('.scroll-down');
        classie.remove(document.body, 'menu-open');

    });

    var Homepage = Barba.BaseView.extend({
        namespace: 'homepage', 
        onEnterCompleted: function() {

            initHomeParallax();
            initTwoStepForm();
            initScrollDownLoop();
            
        }
    });

    var SingleWork = Barba.BaseView.extend({
        namespace: 'single-work', 
        onEnterCompleted: function() {

            // Forzo l'effetto text-fill al caricamento
            setTimeout(function() {
                classie.add(document.querySelector('.single-work__title'), 'waypoint-target');
            }, 500);

            if ( Foundation.MediaQuery.atLeast('medium-hi') ) initStickySections();

            var $emotionalSlider = $('.slick--emotional');

            function watchMedia(mq) {

                if (mq.matches) {

                    $emotionalSlider.slick({
                        dots: true, 
                        arrows: false
                    });

                } else {
                    
                    if ( $emotionalSlider.hasClass('slick-initialized') )
                        $emotionalSlider.slick('unslick');
                    
                }

            }

            if (matchMedia) {
                var mq = window.matchMedia('(max-width: 767px)');
                mq.addListener(watchMedia);
                watchMedia(mq)
            }

        }
    });

    var SinglePage = Barba.BaseView.extend({
        namespace: 'single-page', 
        onEnterCompleted: function() {

            initPageParallax();
            if ( Foundation.MediaQuery.atLeast('medium-hi') ) initStickySections();

            if ( 
                classie.has(document.querySelector('.barba-container'), 'works-page') || 
                classie.has(document.querySelector('.barba-container'), 'services-page')
             ) initTwoStepForm();

        }
    });

    var ContactsPage = Barba.BaseView.extend({
        namespace: 'contacts-page', 
        onEnterCompleted: function() {
            
            new PmdrForm('#contact-form');

            $('#contacts__submit').on('click', function() {
                $('#contact-form').submit();
            });

        }
    });

    var LandingPage = Barba.BaseView.extend({
        namespace: 'landing', 
        onEnterCompleted: function() {

            if ( typeof plyr === 'object' ) {

                landingMobileReel = plyr.setup('.video-reel', {
                    volume: 8
                });

                landingMobileReel[0].on('ended', event => {
                    
                    var tl = new TimelineMax({
                        delay: 1
                    });

                    tl
                        .add('Hide reel')
                        .to('.modal--reel .plyr', 1, {opacity: 0})
                        .set('.modal--reel .plyr', {display: 'none'})

                        .add('show form')
                        .set('.modal--reel .two-step-form', {display: 'block'})
                        .to('.modal--reel .two-step-form', 1, {opacity: 1});

                });

            }

            initScrollDownLoop();
            
            // Initiating contact forms
            initTwoStepForm();
            new PmdrForm('.landing-form');
            new PmdrForm('#contact-form-bottom');

            initLandingForm();
        
        }
    });

    Homepage.init();
    SingleWork.init();
    SinglePage.init();
    ContactsPage.init();
    LandingPage.init();

    Barba.Pjax.init();
    Barba.Prefetch.init();
    /**
     * End BarbaJS
     */

    function render() {

        if ( getScrollY() > (window.innerHeight / 10) ) {
            classie.addClass(document.body, 'scrolled');
        } else {
            classie.removeClass(document.body, 'scrolled');
        }

        fixFooterToBottom();
        setScrollDownOpacity();
        animateInView();
        requestAnimationFrame(render);
        
    }

    if ( 'serviceWorker' in navigator && window.location.protocol === 'https:' ) {

        navigator.serviceWorker
            .register('/sw.js?v=' + Date.now())
            .then(function() {
                console.log('ServiceWorker registered');
            });
    }

})(jQuery);