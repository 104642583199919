/**
 * Instantiates a new form
 * @constructor
 * @param {string} form - String representing the target form. Can be .class or #id
 */
class PmdrForm {

    constructor(form) {
        this.form = jQuery(form);
        this.init();
    }

    /**
     * Initializes the form
     */
    init() {
        
        this.form.parsley()

            .on('field:validated', () => {

                if ( $('.parsley-error').length === 0 )
                    $('.parsley-errors-list').remove();

            })
            .on('form:submit', () => {
                this.submit();
                return false;
            });

    }

    /**
     * Submits the form
     */
    submit() {

        const _self = this;
        const data = this.form.serialize();
        const origin = encodeURI(location.href);
        const form_id = this.form.attr('data-analytics-id');

        console.log('data', data)

        $.ajax({
            type: 'POST',
            url: `${location.protocol}//${location.host}/mail.php`, 
            data: `${data}&origin=${origin}&form_id=${form_id}`,
            dataType: 'json',
            success: res => {

                _self.feedback(res.status);
                
                if (res.status === 'success') {
                    _self.resetForm();
                    _self.sendDataToAnalytics();
                }

            }
        });

    }

    /**
     * Resets the form
     */
    resetForm() {
        this.form.find('.not-empty').removeClass('not-empty').addClass('empty');
        this.form[0].reset();
    }

    /**
     * Displays a confirmation message
     * @private
     * @param {success|error} status
     */
    feedback(status) {

        const message = status === 'success' ? "Messaggio inviato con successo" : "C'è stato un errore durante l'invio del messaggio. Per favore riprova più tardi";
        this.form.find('.form-response').html(`<p class="${status}">${message}</p>`);

    }

    /**
     * Sends custom events to Google Analytics
     */
    sendDataToAnalytics() {

        if ( typeof _gaq !== 'object' ) return;

        const formName = this.form.attr('data-analytics-id');

        _gaq.push(['_setAccount', 'UA-23484389-1']);
        _gaq.push(['_gaq._anonymizeIp']);

        switch(formName) {

            case "Form generico":
            case "Form a 2 step":
                _gaq.push(['_trackEvent', 'CF', 'Invio', 'Invio']);
                // console.log('Triggerato GA form generico');
                break;
            case "Landing form intermedio":
                _gaq.push(['_trackEvent', 'CF', 'Invio', 'Contattami']);
                // console.log('Triggerato GA form landing intermedio');
                break;
            case "Landing form in fondo":
                _gaq.push(['_trackEvent', 'Cambia con noi', 'Invio', 'Contattami']);
                // console.log('Triggerato GA form landing in fondo');
                break;
            default:
                break;

        }

    }

}
